<template>
  <div class="container" v-if="answer">
    <div class="w-100 d-flex justify-content-start ms-8">
      <h2>
        <span style="color: #14993c"> Español </span>
      </h2>
    </div>
    <div class="resume shadow-sm p-4 border">
      <input
        class="form-control form-control-lg wet-form fw-bold mb-5"
        type="text"
        v-model="dataES.title"
      />
      <input
        class="form-control wet-form fw-bold mb-3"
        type="text"
        v-model="dataES.companyData.title"
        style="color: #14993c"
      />
      <div class="row">
        <div class="col-2">
          <input
            class="form-control form-control-sm wet-form fw-bold mb-3"
            type="text"
            v-model="dataES.companyData.rut"
          />
        </div>
        <div class="col-2">
          <p>{{ answer.rut || "---" }}</p>
        </div>
        <div class="col-2">
          <input
            class="form-control form-control-sm wet-form fw-bold mb-3"
            type="text"
            v-model="dataES.companyData.company"
          />
        </div>
        <div class="col-2">
          <p>{{ answer.empresa || "---" }}</p>
        </div>
        <div class="col-2">
          <input
            class="form-control form-control-sm wet-form fw-bold mb-3"
            type="text"
            v-model="dataES.companyData.date"
          />
        </div>
        <div class="col-2">
          <p>
            {{
              answer && answer.sendDate
                ? answer.sendDate.substring(0, 10)
                : "---"
            }}
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-2">
          <input
            class="form-control form-control-sm wet-form fw-bold mb-3"
            type="text"
            v-model="dataES.companyData.country"
          />
        </div>
        <div class="col-2">
          <p>{{ answer.pais || "---" }}</p>
        </div>
        <div class="col-2">
          <input
            class="form-control form-control-sm wet-form fw-bold mb-3"
            type="text"
            v-model="dataES.companyData.web"
          />
        </div>
        <div class="col-2">
          <p>{{ answer.website || "---" }}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-2">
          <input
            class="form-control form-control-sm wet-form fw-bold mb-3"
            type="text"
            v-model="dataES.companyData.industry"
          />
        </div>
        <div class="col-2">
          <p>
            {{
              answer.result.wfResponse.preliminaryValorization.values
                .industry || "---"
            }}
          </p>
        </div>
        <div class="col-2">
          <input
            class="form-control form-control-sm wet-form fw-bold mb-3"
            type="text"
            v-model="dataES.companyData.businessActivity"
          />
        </div>
        <div class="col-2">
          <p>
            {{
              answer.result.wfResponse.preliminaryValorization.values
                .businessActivity || "---"
            }}
          </p>
        </div>
        <div class="col-2">
          <input
            class="form-control form-control-sm wet-form fw-bold mb-3"
            type="text"
            v-model="dataES.companyData.developmentPhase"
          />
        </div>
        <div class="col-2">
          <p>{{ answer.result.valorization.businessPhase || "---" }}</p>
        </div>
      </div>

      <input
        class="form-control wet-form fw-bold mb-3"
        type="text"
        v-model="dataES.contactData.title"
        style="color: #14993c"
      />
      <div class="row">
        <div class="col-2">
          <input
            class="form-control form-control-sm wet-form fw-bold mb-3"
            type="text"
            v-model="dataES.contactData.name"
          />
        </div>
        <div class="col-2">
          <p>{{ answer.contacto || "---" }}</p>
        </div>
        <div class="col-2 offset-4">
          <input
            class="form-control form-control-sm wet-form fw-bold mb-3"
            type="text"
            v-model="dataES.contactData.phone"
          />
        </div>
        <div class="col-2">
          <p>{{ answer.telefono || "---" }}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-2">
          <input
            class="form-control form-control-sm wet-form fw-bold mb-3"
            type="text"
            v-model="dataES.contactData.email"
          />
        </div>
        <div class="col-2">
          <p>{{ answer.email || "---" }}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-2">
          <input
            class="form-control form-control-sm wet-form fw-bold mb-3"
            type="text"
            v-model="dataES.contactData.founders"
          />
        </div>
        <div class="col-2">
          <p>{{ answer.result.valorization.founders || "---" }}</p>
        </div>
        <div class="col-2">
          <input
            class="form-control form-control-sm wet-form fw-bold mb-3"
            type="text"
            v-model="dataES.contactData.openSince"
          />
        </div>
        <div class="col-2">
          <p>{{ answer.anoInicio || "---" }}</p>
        </div>
        <div class="col-2">
          <input
            class="form-control form-control-sm wet-form fw-bold mb-3"
            type="text"
            v-model="dataES.contactData.employees"
          />
        </div>
        <div class="col-2">
          <p>{{ answer.result.valorization.employees }}</p>
        </div>
      </div>

      <input
        class="form-control wet-form fw-bold mb-3"
        type="text"
        v-model="dataES.contactData.businessDescription"
        style="color: #14993c"
      />
      <p>{{ answer.result.valorization.businessDescription || "---" }}</p>
    </div>
    <div class="bg-sweet-green py-4 my-4">
      <div class="row">
        <div class="col-10 px-0 offset-1 custom-card">
          <div class="card shadow-sm p-4 position-relative">
            <img src="../../assets/icons/estrella.png" class="star" alt="" />
            <ckeditor
              :editor="editor"
              v-model="dataES.totalAnswersText"
              :config="editorConfig"
            ></ckeditor>
          </div>
        </div>
      </div>
    </div>
    <DesarrolloEmpresarial :completed="false" />
    <div class="bg-sweet-green py-4 my-4">
      <div class="row">
        <div class="col-10 px-0 offset-1 custom-card">
          <div class="card shadow-sm p-4 position-relative">
            <img src="../../assets/icons/estrella.png" class="star" alt="" />
            <div class="d-flex border-bottom mb-4 pb-3 align-items-center">
              <input
                class="form-control wet-form fw-bold mb-0 me-3"
                type="text"
                v-model="dataES.phase"
                style="width: 90%"
              />
              <span class="green-text">{{
                answer.result.valorization.businessPhase || "---"
              }}</span>
            </div>
            <v-jsoneditor
              v-model="dataES.phaseList"
              :options="options"
              :plus="false"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="assesment my-5">
      <input
        class="form-control form-control-lg wet-form text-center fw-bold mb-3"
        type="text"
        v-model="dataES.assessment.title"
      />
      <div class="px-5 my-4">
        <ckeditor
          :editor="editor"
          v-model="dataES.assessment.text"
          :config="editorConfig"
        ></ckeditor>
      </div>
    </div>
    <div class="preliminar">
      <h2 class="text-center mb-5">
        <input
          class="form-control form-control-lg wet-form text-center fw-bold mb-2"
          type="text"
          v-model="dataES.assessment.preliminary"
        />

        <input
          class="form-control form-control-lg wet-form text-center fw-bold mb-2"
          type="text"
          v-model="dataES.assessment.company"
          style="color: #23af4d"
        />
        <span style="color: #23af4d"> : {{ answer.empresa || "---" }} </span>
      </h2>
      <div class="row">
        <div class="col-12 col-md-3 me-2 bg-sweet-green shadow-sm p-3 h-100">
          <div>
            <h5 style="word-break: break-word">
              {{ answer.empresa || "---" }}
            </h5>
            <h5 class="text-center blue-border">
              {{ sum8x8(answer.result.wfResponse.preliminaryAssesment.values) }}
              %
            </h5>
          </div>
          <div>
            <input
              class="form-control wet-form fw-bold mb-2"
              type="text"
              v-model="dataES.assessment.chartLegend.recommended"
            />
            <h5 class="text-center green-border">80%</h5>
          </div>
          <div>
            <input
              class="form-control wet-form fw-bold mb-2"
              type="text"
              v-model="dataES.assessment.chartLegend.optimus"
            />
            <h5 class="text-center brown-border">100%</h5>
          </div>
        </div>
        <div class="col-12 col-md-8">
          <AssesmentChart
            :company-name="answer.empresa"
            :company-points="
              Object.values(
                answer.result.wfResponse.preliminaryAssesment.values
              )
            "
          />
        </div>
      </div>
    </div>
    <div class="valorizacion bg-sweet-green mt-5 p-5">
      <ckeditor
        :editor="editor"
        v-model="dataES.valorization.title"
        :config="editorConfig"
      ></ckeditor>

      <ckeditor
        :editor="editor"
        v-model="dataES.valorization.text"
        :config="editorConfig"
      ></ckeditor>
      <div class="row">
        <div class="col-12 col-md-3">
          <div class="sweet-blue-card shadow-sm">
            <img src="../../assets/icons/low-bound.png" width="40px" alt="" />
            <input
              class="form-control form-control-sm wet-form text-center fw-bold my-2"
              type="text"
              v-model="dataES.valorization.lowBound"
            />
            <h5 style="color: #1d70b7">
              US$
              {{
                numberWithCommas(
                  answer.result.wfResponse.preliminaryValorization.values
                    .lowBound
                ) || "---"
              }}
            </h5>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="sweet-green-card shadow-sm">
            <input
              class="form-control wet-form text-center fw-bold my-2"
              type="text"
              v-model="dataES.valorization.preMoneyEvaluation"
            />
            <h3 style="color: #18a03e">
              US$
              {{
                numberWithCommas(
                  answer.result.wfResponse.preliminaryValorization.values
                    .preMoneyValuation
                ) || "---"
              }}
            </h3>
          </div>
        </div>
        <div class="col-12 col-md-3">
          <div class="sweet-blue-card shadow-sm">
            <img src="../../assets/icons/high-bound.png" width="40px" alt="" />

            <input
              class="form-control form-control-sm wet-form text-center fw-bold my-2"
              type="text"
              v-model="dataES.valorization.highBound"
            />
            <h5 style="color: #1d70b7">
              US$
              {{
                numberWithCommas(
                  answer.result.wfResponse.preliminaryValorization.values
                    .highBound
                ) || "---"
              }}
            </h5>
          </div>
        </div>
      </div>
      <div class="py-4 my-4">
        <div class="row">
          <div class="col-10 px-0 offset-1 custom-card">
            <div class="card shadow-sm p-4 position-relative">
              <img src="../../assets/icons/estrella.png" class="star" alt="" />
              <div
                class="d-flex border-bottom mb-4 pb-3 align-items-center justify-content-center"
              >
                <input
                  class="form-control wet-form text-center fw-bold mb-0 me-3"
                  type="text"
                  v-model="dataES.valorization.preliminary"
                />
                <span class="green-text d-inline-flex me-3">
                  <input
                    class="form-control wet-form fw-bold mb-0 me-2"
                    type="text"
                    v-model="dataES.valorization.company"
                  />
                  {{ answer.empresa || "---" }}</span
                >
              </div>

              <input
                class="form-control wet-form fw-bold mb-2"
                type="text"
                v-model="dataES.valorization.nextStep"
                style="color: #23af4d"
              />

              <textarea
                class="form-control wet-form mb-0"
                rows="6"
                type="text"
                v-model="dataES.valorization.nextStepText"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="w-100 d-flex justify-content-start ms-8">
      <button
        class="btn btn-success btn-lg mt-3 mb-3"
        @click="syncContent('ES')"
      >
        Guardar
      </button>
    </div>

    <div class="w-100">
      <hr class="pill my-5" />
    </div>

    <div class="w-100 d-flex justify-content-start ms-8">
      <h2>
        <span style="color: #14993c"> English </span>
      </h2>
    </div>
    <div class="resume shadow-sm p-4 border">
      <input
        class="form-control form-control-lg wet-form fw-bold mb-5"
        type="text"
        v-model="dataEN.title"
      />
      <input
        class="form-control wet-form fw-bold mb-3"
        type="text"
        v-model="dataEN.companyData.title"
        style="color: #14993c"
      />
      <div class="row">
        <div class="col-2">
          <input
            class="form-control form-control-sm wet-form fw-bold mb-3"
            type="text"
            v-model="dataEN.companyData.rut"
          />
        </div>
        <div class="col-2">
          <p>{{ answer.rut || "---" }}</p>
        </div>
        <div class="col-2">
          <input
            class="form-control form-control-sm wet-form fw-bold mb-3"
            type="text"
            v-model="dataEN.companyData.company"
          />
        </div>
        <div class="col-2">
          <p>{{ answer.empresa || "---" }}</p>
        </div>
        <div class="col-2">
          <input
            class="form-control form-control-sm wet-form fw-bold mb-3"
            type="text"
            v-model="dataEN.companyData.date"
          />
        </div>
        <div class="col-2">
          <p>
            {{
              answer && answer.sendDate
                ? answer.sendDate.substring(0, 10)
                : "---"
            }}
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-2">
          <input
            class="form-control form-control-sm wet-form fw-bold mb-3"
            type="text"
            v-model="dataEN.companyData.country"
          />
        </div>
        <div class="col-2">
          <p>{{ answer.pais || "---" }}</p>
        </div>
        <div class="col-2">
          <input
            class="form-control form-control-sm wet-form fw-bold mb-3"
            type="text"
            v-model="dataEN.companyData.web"
          />
        </div>
        <div class="col-2">
          <p>{{ answer.website || "---" }}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-2">
          <input
            class="form-control form-control-sm wet-form fw-bold mb-3"
            type="text"
            v-model="dataEN.companyData.industry"
          />
        </div>
        <div class="col-2">
          <p>
            {{
              answer.result.wfResponse.preliminaryValorization.values
                .industry || "---"
            }}
          </p>
        </div>
        <div class="col-2">
          <input
            class="form-control form-control-sm wet-form fw-bold mb-3"
            type="text"
            v-model="dataEN.companyData.businessActivity"
          />
        </div>
        <div class="col-2">
          <p>
            {{
              answer.result.wfResponse.preliminaryValorization.values
                .businessActivity || "---"
            }}
          </p>
        </div>
        <div class="col-2">
          <input
            class="form-control form-control-sm wet-form fw-bold mb-3"
            type="text"
            v-model="dataEN.companyData.developmentPhase"
          />
        </div>
        <div class="col-2">
          <p>{{ answer.result.valorization.businessPhase || "---" }}</p>
        </div>
      </div>

      <input
        class="form-control wet-form fw-bold mb-3"
        type="text"
        v-model="dataEN.contactData.title"
        style="color: #14993c"
      />
      <div class="row">
        <div class="col-2">
          <input
            class="form-control form-control-sm wet-form fw-bold mb-3"
            type="text"
            v-model="dataEN.contactData.name"
          />
        </div>
        <div class="col-2">
          <p>{{ answer.contacto || "---" }}</p>
        </div>
        <div class="col-2 offset-4">
          <input
            class="form-control form-control-sm wet-form fw-bold mb-3"
            type="text"
            v-model="dataEN.contactData.phone"
          />
        </div>
        <div class="col-2">
          <p>{{ answer.telefono || "---" }}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-2">
          <input
            class="form-control form-control-sm wet-form fw-bold mb-3"
            type="text"
            v-model="dataEN.contactData.email"
          />
        </div>
        <div class="col-2">
          <p>{{ answer.email || "---" }}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-2">
          <input
            class="form-control form-control-sm wet-form fw-bold mb-3"
            type="text"
            v-model="dataEN.contactData.founders"
          />
        </div>
        <div class="col-2">
          <p>{{ answer.result.valorization.founders || "---" }}</p>
        </div>
        <div class="col-2">
          <input
            class="form-control form-control-sm wet-form fw-bold mb-3"
            type="text"
            v-model="dataEN.contactData.openSince"
          />
        </div>
        <div class="col-2">
          <p>{{ answer.anoInicio || "---" }}</p>
        </div>
        <div class="col-2">
          <input
            class="form-control form-control-sm wet-form fw-bold mb-3"
            type="text"
            v-model="dataEN.contactData.employees"
          />
        </div>
        <div class="col-2">
          <p>{{ answer.result.valorization.employees }}</p>
        </div>
      </div>

      <input
        class="form-control wet-form fw-bold mb-3"
        type="text"
        v-model="dataEN.contactData.businessDescription"
        style="color: #14993c"
      />
      <p>{{ answer.result.valorization.businessDescription || "---" }}</p>
    </div>
    <div class="bg-sweet-green py-4 my-4">
      <div class="row">
        <div class="col-10 px-0 offset-1 custom-card">
          <div class="card shadow-sm p-4 position-relative">
            <img src="../../assets/icons/estrella.png" class="star" alt="" />
            <ckeditor
              :editor="editor"
              v-model="dataEN.totalAnswersText"
              :config="editorConfig"
            ></ckeditor>
          </div>
        </div>
      </div>
    </div>
    <DesarrolloEmpresarial :completed="false" />
    <div class="bg-sweet-green py-4 my-4">
      <div class="row">
        <div class="col-10 px-0 offset-1 custom-card">
          <div class="card shadow-sm p-4 position-relative">
            <img src="../../assets/icons/estrella.png" class="star" alt="" />
            <div class="d-flex border-bottom mb-4 pb-3 align-items-center">
              <input
                class="form-control wet-form fw-bold mb-0 me-3"
                type="text"
                v-model="dataEN.phase"
                style="width: 90%"
              />
              <span class="green-text">{{
                answer.result.valorization.businessPhase || "---"
              }}</span>
            </div>
            <v-jsoneditor
              v-model="dataEN.phaseList"
              :options="options"
              :plus="false"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="assesment my-5">
      <input
        class="form-control form-control-lg wet-form text-center fw-bold mb-3"
        type="text"
        v-model="dataEN.assessment.title"
      />
      <div class="px-5 my-4">
        <ckeditor
          :editor="editor"
          v-model="dataEN.assessment.text"
          :config="editorConfig"
        ></ckeditor>
      </div>
    </div>
    <div class="preliminar">
      <h2 class="text-center mb-5">
        <input
          class="form-control form-control-lg wet-form text-center fw-bold mb-2"
          type="text"
          v-model="dataEN.assessment.preliminary"
        />

        <input
          class="form-control form-control-lg wet-form text-center fw-bold mb-2"
          type="text"
          v-model="dataEN.assessment.company"
          style="color: #23af4d"
        />
        <span style="color: #23af4d"> : {{ answer.empresa || "---" }} </span>
      </h2>
      <div class="row">
        <div class="col-12 col-md-3 me-2 bg-sweet-green shadow-sm p-3 h-100">
          <div>
            <h5 style="word-break: break-word">
              {{ answer.empresa || "---" }}
            </h5>
            <h5 class="text-center blue-border">
              {{ sum8x8(answer.result.wfResponse.preliminaryAssesment.values) }}
              %
            </h5>
          </div>
          <div>
            <input
              class="form-control wet-form fw-bold mb-2"
              type="text"
              v-model="dataEN.assessment.chartLegend.recommended"
            />
            <h5 class="text-center green-border">80%</h5>
          </div>
          <div>
            <input
              class="form-control wet-form fw-bold mb-2"
              type="text"
              v-model="dataEN.assessment.chartLegend.optimus"
            />
            <h5 class="text-center brown-border">100%</h5>
          </div>
        </div>
        <div class="col-12 col-md-8">
          <AssesmentChart
            :company-name="answer.empresa"
            :company-points="
              Object.values(
                answer.result.wfResponse.preliminaryAssesment.values
              )
            "
          />
        </div>
      </div>
    </div>
    <div class="valorizacion bg-sweet-green mt-5 p-5">
      <ckeditor
        :editor="editor"
        v-model="dataEN.valorization.title"
        :config="editorConfig"
      ></ckeditor>

      <ckeditor
        :editor="editor"
        v-model="dataEN.valorization.text"
        :config="editorConfig"
      ></ckeditor>
      <div class="row">
        <div class="col-12 col-md-3">
          <div class="sweet-blue-card shadow-sm">
            <img src="../../assets/icons/low-bound.png" width="40px" alt="" />
            <input
              class="form-control form-control-sm wet-form text-center fw-bold my-2"
              type="text"
              v-model="dataEN.valorization.lowBound"
            />
            <h5 style="color: #1d70b7">
              US$
              {{
                numberWithCommas(
                  answer.result.wfResponse.preliminaryValorization.values
                    .lowBound
                ) || "---"
              }}
            </h5>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="sweet-green-card shadow-sm">
            <input
              class="form-control wet-form text-center fw-bold my-2"
              type="text"
              v-model="dataEN.valorization.preMoneyEvaluation"
            />
            <h3 style="color: #18a03e">
              US$
              {{
                numberWithCommas(
                  answer.result.wfResponse.preliminaryValorization.values
                    .preMoneyValuation
                ) || "---"
              }}
            </h3>
          </div>
        </div>
        <div class="col-12 col-md-3">
          <div class="sweet-blue-card shadow-sm">
            <img src="../../assets/icons/high-bound.png" width="40px" alt="" />

            <input
              class="form-control form-control-sm wet-form text-center fw-bold my-2"
              type="text"
              v-model="dataEN.valorization.highBound"
            />
            <h5 style="color: #1d70b7">
              US$
              {{
                numberWithCommas(
                  answer.result.wfResponse.preliminaryValorization.values
                    .highBound
                ) || "---"
              }}
            </h5>
          </div>
        </div>
      </div>
      <div class="py-4 my-4">
        <div class="row">
          <div class="col-10 px-0 offset-1 custom-card">
            <div class="card shadow-sm p-4 position-relative">
              <img src="../../assets/icons/estrella.png" class="star" alt="" />
              <div
                class="d-flex border-bottom mb-4 pb-3 align-items-center justify-content-center"
              >
                <input
                  class="form-control wet-form text-center fw-bold mb-0 me-3"
                  type="text"
                  v-model="dataEN.valorization.preliminary"
                />
                <span class="green-text d-inline-flex me-3">
                  <input
                    class="form-control wet-form fw-bold mb-0 me-2"
                    type="text"
                    v-model="dataEN.valorization.company"
                  />
                  {{ answer.empresa || "---" }}</span
                >
              </div>

              <input
                class="form-control wet-form fw-bold mb-2"
                type="text"
                v-model="dataEN.valorization.nextStep"
                style="color: #23af4d"
              />

              <textarea
                class="form-control wet-form mb-0"
                rows="6"
                type="text"
                v-model="dataEN.valorization.nextStepText"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="w-100 d-flex justify-content-start ms-8">
      <button
        class="btn btn-success btn-lg mt-3 mb-3"
        @click="syncContent('EN')"
      >
        Save
      </button>
    </div>
  </div>
</template>

<script>
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import VJsoneditor from "v-jsoneditor/src/index";
import DesarrolloEmpresarial from "../../components/models-programs/DesarrolloEmpresarial";
import AssesmentChart from "../../components/AssesmentChart";
import { getAnswerById } from "../../helpers/admin";
import store from "../../store";
import { getSection, putSection } from "../../helpers/contenido";

export default {
  name: "Results",
  components: { DesarrolloEmpresarial, AssesmentChart, VJsoneditor },
  data() {
    return {
      dataES: {},
      dataEN: {},
      answer: {},
      email: "",
      id: JSON.parse(localStorage.userData)._id,

      editor: ClassicEditor,
      editorConfig: {
        // The configuration of the editor.
      },
    };
  },
  methods: {
    sum8x8(obj) {
      const values = Object.values(obj);
      const calc = values.reduce((prev, next) => prev + next);
      return Math.round(calc / 8);
    },
    async getAnswer(id) {
      const response = await getAnswerById(id);
      this.answer = response;
    },
    fetchData() {
      if (store.state.sections.result) {
        this.sectionData = store.state.sections.result.components;
      }
    },
    numberWithCommas(num) {
      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },

    async fetchContent() {
      const section = await getSection("result");
      const sectionES = section.filter((section) => section.language == "ES");
      const sectionEN = section.filter((section) => section.language == "EN");

      this.sectionES = sectionES;
      this.sectionEN = sectionEN;

      this.dataES = sectionES[0].components;

      this.dataEN = sectionEN[0].components;
    },
    async syncContent(lan) {
      if (lan === "ES") {
        const content = {
          id: this.sectionES[0]._id,
          section: "result",
          components: this.dataES,
          language: "ES",
        };
        await putSection(content);
      } else {
        const content = {
          id: this.sectionEN[0]._id,
          section: "result",
          components: this.dataEN,
          language: "EN",
        };
        await putSection(content);
      }
    },
    onError() {
      console.log("error");
    },
  },
  mounted() {
    this.getAnswer(this.id);
    this.fetchData();
    this.fetchContent();
  },
  watch: {
    "$store.state.sections": function () {
      this.fetchData();
    },
  },
};
</script>

<style scoped>
hr.pill {
  height: 0px;
  margin: auto auto;
  border-radius: 2px;
  color: #14993c !important;
  border: 2px solid currentColor;
  width: 80%;
}

.resume {
  border-radius: 15px;
}

h6 {
  margin: 1rem 0;
}

.row {
  margin: 0.5rem 0;
}

.custom-card {
  border: 1px solid yellow;
}

.star {
  position: absolute;
  width: 30px;
  top: -12px;
  left: -12px;
}

.green-text {
  color: white;
  background-color: #23af4d;
  padding: 0.5rem;
  border-radius: 5px;
  font-weight: bold;
}

.bg-sweet-green {
  background-color: #effff4;
  border-radius: 10px;
}

.blue-border::after {
  content: "";
  display: inherit;
  width: 50%;
  margin: 10px auto;
  border-top: 3px solid rgb(102, 153, 255);
}

.green-border::after {
  content: "";
  display: inherit;
  width: 50%;
  margin: 10px auto;
  border-top: 3px solid #23af4d;
}

.brown-border::after {
  content: "";
  display: inherit;
  width: 50%;
  margin: 10px auto;
  border-top: 3px solid rgb(204, 153, 0);
}

.sweet-blue-card {
  border-radius: 5px;
  background-color: #c2edf9;
  text-align: center;
  padding: 1rem;
}

.sweet-green-card {
  margin-top: 1rem;
  text-align: center;
  background-color: #bfefcc;
  border-radius: 5px;
  padding: 2rem;
}
</style>
